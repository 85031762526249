import { formatDate } from "devextreme/localization";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { endOfYear, startOfYear, parseISO, format } from "date-fns";
import { escape } from "lodash";

const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
const DATE_FORMAT = "yyyy-MM-dd";
 
export function DateFilter(propertyName: string, date: any) : string {
    let startDate = startOfDay(new Date (date));
    let endDate = endOfDay(new Date (date));
    return `${propertyName} > "${formatDate(startDate, DATE_TIME_FORMAT)}" && ${propertyName} < "${formatDate(endDate, DATE_TIME_FORMAT)}"` 
}

export function DateRangeFilter(propertyName: string, range: Array<any>) : string {
    let filterString = '';
    if(range[0]){
        let date = range[0] instanceof Date ? range[0] : parseISO(range[0]);
        let startDate = startOfDay(date);
        filterString = `${propertyName} >= "${format(startDate, DATE_TIME_FORMAT)}"`;
    }
    if(range[1]){
        let date = range[1] instanceof Date ? range[1] : parseISO(range[1]);
        let endDate = endOfDay(date);
        if(filterString){
            filterString = `${filterString} &&`;
        }
        filterString = `${filterString} ${propertyName} < "${format(endDate, DATE_TIME_FORMAT)}"`
    }
    return filterString;
}

export function FromDateFilter(propertyName: string, date: Date) : string {
    let startDate = startOfDay(new Date(date)); 
    return `${propertyName} > "${formatDate(startDate, DATE_FORMAT)}"` 
}

export function ToDateFilter(propertyName: string, date: Date) : string {
    let endDate = endOfDay(new Date (date));
    return `${propertyName} < "${formatDate(endDate, DATE_FORMAT)}"` 
}

// export function ContainsFilter(propertyName: string, value: string) : string {
//     let filterString = `${propertyName}.Contains("${escape(value)}")`;
//     return filterString;
// }

export function ContainsFilter(propertyName: string, value: any) : string {
    let filterString = `${propertyName}.Contains("${escape(value).trim()}")`;
    return filterString;
}


export function YearFilter(propertyName: string, year: Date) : string {
    let startYear = startOfYear(new Date(year));
    let endYear = endOfYear(new Date(year));
    return `${propertyName} > "${formatDate(startYear, DATE_TIME_FORMAT)}" && ${propertyName} < "${formatDate(endYear, DATE_TIME_FORMAT)}"` 
}

export function MultiSelectFormatter(propertyName: string, range: Array<any>) {
    let filterString = '';
    if (range.length > 0) {
      filterString = range.map(value => `${propertyName} == "${value}"`).join(' || ');
      filterString = `(${filterString})`;
    }
    return filterString;
}

export function FromSerialNumberFilter(propertyName: string, value: string): string {
    if (value) {
        return `${propertyName} >= "${value}"`;
    }
    return '';
}

export function ToSerialNumberFilter(propertyName: string, value: string): string {
    if (value) {
        return `${propertyName} <= "${value}"`;
    }
    return '';
}